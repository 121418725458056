import { Button, Divider, Form, Input, Modal, Image } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { } from 'react';
import { useParams } from 'react-router-dom';
import { postFoundItemRequest } from '../../effects';
import { FoundItemFormValues } from '../../models';
import './FoundItemForm.less'
import sirFoundalotImage from '../../assets/sir-foundalot.png';

const initialValues: FoundItemFormValues = {
  name: '',
  email: '',
  phone: '',
  foundItemDescription: '',
  foundItemLocation: '',
};

const PosForm: React.FC = () => {
  const { companyDomain } = useParams<{ companyDomain: string }>();

  const onFinish = (values: typeof initialValues) => {
    const { ...requestBody } = { ...values, companyDomain }

    postFoundItemRequest(requestBody).then(() => Modal
      .success({
        style: { justifyContent: 'center', justifyItems: "center" },
        icon: (<Image style={{ marginRight: '16px' }} width={200} src={sirFoundalotImage} />),
        content: 'Thank you! We\'ll be in contact shortly to arrange the return of the item.',
        onOk: () => form.resetFields()
      }))
      .catch(() => Modal
        .error({
          centered: true,
          content: 'Sorry, something went wrong during submission. Please email support@found.cloud instead.',
          onOk: () => form.resetFields()
        }));
  };
  const [form] = Form.useForm<typeof initialValues>();

  return (
    <Form
      form={form}
      name='foundItem'
      initialValues={initialValues}
      onFinish={onFinish}
      layout='vertical'
    >
      <Text>Tell us about you.</Text><br />
      <Text strong>Your details will only be used for arranging the item's return.</Text>
      <Form.Item
        label={'What\'s your name?'}
        name='name'
        rules={[{
          type: 'string',
          required: true,
          message: 'Please tell us your name'
        }]}
      >
        <Input placeholder={'Sir Foundalot'} />
      </Form.Item>

      <Form.Item
        label={'What\'s your email address?'}
        name='email'
        rules={[{
          type: 'email',
          required: true,
          message: 'Please supply a valid email address'
        }]}
      >
        <Input placeholder={'foundalot@found.cloud'} />
      </Form.Item>

      <Form.Item
        label={'What\'s your phone number?'}
        name='phone'
        rules={[{
          type: 'string',
          pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
          required: false,
          message: 'Please supply a valid phone number'
        }]}
      >
        <Input placeholder={'07777888666'} />
      </Form.Item>

      <Divider />

      <Text>Tell us about the item you found.</Text>
      <Form.Item
        label={'Describe the item'}
        name='foundItemDescription'
        rules={[{
          type: 'string',
          required: true,
          message: 'Please describe the item'
        }]}
      >
        <Input.TextArea placeholder={'Silver Dell laptop'} rows={2} />
      </Form.Item>

      <Form.Item
        label={'Describe where you found the item'}
        name='foundItemLocation'
        rules={[{
          type: 'string',
          required: false,
          message: 'Please describe where you found the item'
        }]}
      >
        <Input.TextArea placeholder={'Bristol Temple Meads station'} rows={2} />
      </Form.Item>

      <Form.Item>
        <Text>
          <a
            href='https://www.found.cloud/privacy-policy-legal'
            rel="noopener noreferrer"
            target="_blank">View our privacy policy
        </a>
        </Text>
      </Form.Item>

      <Form.Item>
        <Button size={'large'} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default PosForm;
